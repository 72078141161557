<template>
  <div id="ovg-properties">
    <Gallery :activeSlide="activeSlide" :images="properties" @changeSlide="galleryUpdatedSlide"/>    
    <div class="add-padding">
        <div class='limit-width'>
            <PageTitle title="OVG Properties"/>
            <div class="properties">
                <h4 class='white'>Developing the Single Largest Development of World-Class Arenas in the History of the Live Entertainment Industry</h4>      
                <div class='ui'>
                    <div class='property' v-for="(property, id) in properties" :key="property.title" :class="[activeSlide == id ? 'active' : '']">
                        <div>
                            <button @click="activeSlide = id" class='body white'>
                                <span>{{property.title}}</span>                    
                                <svg width="7" height="15" viewBox="0 0 7 12" fill="none">
                                    <path d="M1.625 1.125L6 5.92683L1.625 10.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                            <div class="info body">
                                <div>
                                    <div class="body white">{{property.description}}</div>
                                    <div class='opening-fall'>
                                        <h4 class='white'>{{property.open}}</h4>
                                        <h2 class='accent'>{{property.year}}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>            
                    </div>            
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import Gallery from '@/components/Gallery';
import PageTitle from '@/components/PageTitle';
export default {
    name:'OVGProperties',
    components: {
        Gallery,
        PageTitle
    },
    methods: {
        galleryUpdatedSlide(value) {
            this.activeSlide = value;
        }
    },
    data: () => ({   
      activeSlide:0,
      properties: [
          {
              title:'Climate Pledge Arena',
              file:'oak-view-group/properties/climate.jpg',
              description:'The first net zero carbon certified arena in the world setting a new sustainability standard for sports and event spaces',
              open:'Opening Fall',
              year:'2021'            
          },
          {
              title:'UBS Arena',
              file:'oak-view-group/properties/ubs.jpg',
              description:'Transforming the historic Belmont Park site into a timeless classic',
              open:'Opening Fall',
              year:'2021'            
          },
          {
              title:'Moody Center',
              file:'oak-view-group/properties/moody.jpg',
              description:'The first of its kind in Austin, Texas where every world-class act will want to play',
              open:'Opening Q1',
              year:'2022'            
          },
          {
              title:'Enmarket Arena',
              file:'oak-view-group/properties/enmarket.jpg',
              description:'The unrivaled new sports & entertainment venue of the south. Built by and for the community',
              open:'Opening February',
              year:'2022'            
          },
          {
              title:'Coachella Valley Arena',
              file:'oak-view-group/properties/coachella.jpg',
              description:'A destination in the desert, the next crown jewel of sports and entertainment in Southern California',
              open:'Opening February',
              year:'2022'            
          },
          {
              title:'Co-Op Live',
              file:'oak-view-group/properties/coop.jpg',
              description:'The arena Manchester deserves, the first venue designed for music: the fans and the bands',
              open:'Opening Q1',
              year:'2023'            
          },
          {
              title:'Cardiff Arena',
              file:'oak-view-group/properties/cardiff.jpg',
              description:'A modern, flexible Arena for the next generation of fans',
              open:'Opening Winter',
              year:'2023'            
          }
      ]
  }),
}
</script>

<style lang="scss" scoped>
#ovg-properties {
    min-height:calc(100vh - 140px);
    h4 {
      max-width: 525px;
    }
}
.opening-fall {
    text-align: right;
}
.ui {    
    margin-top:30px;
}
.info {
    background: rgba(32, 32, 32, 0.75);    
    vertical-align: top;    
    pointer-events: none;        
    box-sizing: border-box;
    transition: all 0.25s cubic-bezier(1, 0.5, 0.8, 1);
    > div {
        display: flex;
        align-self: flex-start;
        flex-grow: 0;
        flex-shrink: 1;    
        padding:25px;
        box-sizing: border-box;
    }
    .body {
        max-width: 230px;
    }    
    .opening-fall {
        padding-left:30px;
    }
}
.property {    
    > div {
        display: block;  
        position: relative;  
    }
    button {
        width: 265px;
        height:40px;
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        background: rgba(32, 32, 32, 0.75);
        border:none;
        transition: all 0.25s cubic-bezier(1, 0.5, 0.8, 1);
        text-transform: uppercase;
        padding:10px 15px;
        padding-bottom: 8px;
        svg path {
            transition: stroke 0.25s cubic-bezier(1, 0.5, 0.8, 1);
            stroke:#E43141;
        }
    }
    &.active {
        button {
            background: #E43141;
            color:#fff;
        }
        svg {
            transform:scaleX(-1);
            path {
                stroke:#202020;
            }
        }
        .info {
            transform:translateX(0);
            opacity: 1;
        }
    }
}
@media (max-width:811px) {
    .properties {
        padding-bottom:100px;
    }
    .info {
        max-height:0;
        overflow:hidden;
    }
    svg {
        transform: rotateZ(90deg);
    }
    .property {
        button {
            width:100%;
        }
        &.active {
            .info {
                max-height:500px;
            }
            svg {
                transform: rotateZ(270deg);
            }
        }
    }        
}
@media (min-width:812px) {
    .property > div {
        display:inline-block;
    }
    .info {
        position: absolute;
        top:0;
        left:100%;
        opacity: 0;
        transform:translateX(-15px);
        width: 430px;
    }
}
</style>