<template>
    <div id="leadership" class='dark-gradient' :style="`background-image:url('${require('@/assets/images/oak-view-group/innovators.jpg')}')`">
      <div class="add-padding">
        <div class='limit-width'>
          <PageTitle title="Led By<br/>Industry Innovators"/>
          <div class="innovators">
            <Innovator v-for="(innovator, id) in innovators" :key="innovator.name" :info="innovator" :open="id === 0 ? true : false"/>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import Innovator from '@/components/Innovator';
import PageTitle from '@/components/PageTitle';
export default {
    name:'Innovators',
    components: {      
      Innovator,
      PageTitle
  },
    data: () => ({   
      innovators : [
        {
          name:'Tim Leiweke',
          shortDescription:'Expertise in Developing World-Class Arenas',
          image:'oak-view-group/tim-leiweke.jpg',
          longDescription:'Leiweke is a leader and veteran in the sports and entertainment industry with over 40 years of experience. From being one of the youngest sports executives in the history of the NBA and NHL, to becoming the CEO of AEG corporation, Tim’s trademark has been to leave each stop in his career better and significantly stronger than where he found it. He has helped create some of the world’s most iconic music festivals and has built many championship teams across the NBA, NHL, MLS, AHL, and DEL.',
        },
        {
          name:'Irving Azoff',
          shortDescription:'Music Mogul with Key Artist Insights',
          image:'oak-view-group/irving-azoff.jpg',
          longDescription:'Azoff has over 40 years experience in the entertainment industry, and Billboard has named him the most powerful person in the music industry. In 1974, he founded Front Line Management and became the exclusive manager for the likes of the Eagles, Fleetwood Mac, Travis Scott, Harry Styles, Christina Aguilera, Journey, Bon Jovi, and Van Halen. He later became CEO of Ticketmaster, Chairman of Live Nation, and Chairman and CEO of MSG Entertainment.',
        },
        {
          name:'Peter Luukko',
          shortDescription:'Co-Chairman, OVG Facilities & Alliances',
          image:'oak-view-group/peter-luukko.jpg',
          longDescription:'Luukko founded Global Spectrum (now Spectra) and led its growth to more than 200 accounts while he was President of Comcast- Spectator from 1993-2013. He has 35 years of industry experience, including serving as a Regional Vice President for SMG, as well as the lead executive for both the Philadelphia Flyers and Florida Panthers of the National Hockey League (NHL), where he also served on the League’s Board of Governors.',
        },            
      ],      
  }),
}
</script>

<style lang="scss" scoped>
    #leadership {
        background-repeat: no-repeat;
        background-size:cover;
        padding-bottom:75px;
    }
</style>