<template>
  <div id="live-nation" class='add-padding'>
      <div class="limit-width">
        <div class='absolute' >
          <PageTitle dark title="Live Nation"/>
        </div>
      </div>
      <div class="scroll-small-window" style="overflow-y:hidden;">
        <img src="@/assets/images/oak-view-group/live-nation-map.jpg" alt="" style="min-width: 1000px; margin-top:2rem;">
      </div>    
    </div>
</template>

<script>
import PageTitle from '@/components/PageTitle';
export default {
    name:"LiveNation",
    components: {
      PageTitle,      
  },
}
</script>

<style lang="scss" scoped>
#live-nation { 
    background-color: #fff;    
    padding-bottom:45px;
    min-height:calc(100vh - 140px);    
    img {
        height:inherit;
        width: 100%;
        object-fit: contain;
        display: block;
        margin:0 auto;        
        max-width: 1300px;
    }
  }
</style>