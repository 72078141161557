<template>
   <div class="top" id="about-ovg">  
      <Gallery showTitles :images="[{file:'welcome/canal-night.jpg', title: ''}, {file:'welcome/placeholder.jpg', title: 'Climate Pledge Arena in Seattle'}]"/>
      <transition name="slide-fade" mode="out-in" appear>
      <div class='limit-width'>
        <h1 class="white">We Are the Largest Developer of Sports and Entertainment Facilities in the World.</h1>
        <div class='one-line'>
          <em class="accent">$4.5+</em>
          <h1 class="white">Billion</h1>
        </div>
        <h4 class="white">Committed Spending on New Arena Developments Over the Next 3 Years</h4>      
      </div> 
      </transition>    
    </div>
</template>

<script>
import Gallery from '@/components/Gallery';
export default {
    name:'AboutOVG',
    components: {
      Gallery,      
    },
}
</script>

<style lang="scss" scoped>
.top {    
    h1 {
      max-width: 650px;
      margin-top:22px;
      margin-bottom:12px;
    }      
    .cta {
      margin-top: 20px;
    }
  }
</style>