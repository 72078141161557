<template>
  <div class='innovator'>
      <button @click="open = !open" :class="[open ? 'open' : '']">
        <div class='details'>
            <div>
                <h3 class='accent'>{{info.name}}</h3>
                <h4 class="white">{{info.shortDescription}}</h4>
            </div>            
        </div>
        <img class='photo' :src="require(`@/assets/images/${info.image}`)" :alt="info.image">

        <div class="long-description body"><div>{{info.longDescription}}</div></div>
        <div class='arrow'><Arrow /></div>
      </button>
  </div>
</template>

<script>
import Arrow from '@/svg/arrow';
export default {
    name:'Innovator',
    props : {
        info:Object,
        open:Boolean
    },
    components : {
        Arrow
    },  
}
</script>

<style lang="scss" scoped>
    .photo {
        height:inherit;
        display: inline-block;
        vertical-align: top;
    }
    .details {
        padding:2em;
        padding-bottom:1.5em;
        max-width: 285px;
        display: inline-block;        
        align-items: center;
        height: inherit;
        box-sizing: border-box;
        width: 285px;
        h4 {
            max-width: 200px;
        }
    }
    .arrow {
        display: none;
        padding:0 15px;
        align-items: center;
        justify-content: center;
        align-self: center;
    }
    .innovator {        
        > button {
            text-align: left;
            background-color:rgba(32, 32, 32, 0.75);
            transition: background-color 0.25s cubic-bezier(1, 0.5, 0.8, 1), height 0.25s cubic-bezier(1, 0.5, 0.8, 1);
            position: relative;
            padding:0;            
            .accent {
                transition: color 0.25s cubic-bezier(1, 0.5, 0.8, 1);
                margin-bottom: 7px;
            }
            svg {
                transition: all 0.25s cubic-bezier(1, 0.5, 0.8, 1);
            } 
            &.open {
                svg {
                    transform: scaleX(-1);
                }
                .long-description {
                    max-width: 400px;
                }
            }           
            &:hover, &.open {
                background-color:#E43141;
                .accent {
                    color:#ffffff;
                }
                svg {
                    stroke:#202020;
                }
            }
        }
        &:not(:last-child) {
            margin-bottom:15px;
        }        
    }
    .long-description {
        max-width:0;
        background:#fff;
        transition: max-width 0.25s cubic-bezier(1, 0.5, 0.8, 1);         
        overflow: auto;  
        &::-webkit-scrollbar {
            width: 4px;
        }
        &::-webkit-scrollbar-track {
            background-color: #202020;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #484848;
        }
        > div {  
            padding:1.2rem;
        }
    }
    button {        
        background:none;
        border:none;
        cursor: pointer;
         svg {
            stroke:#E43141;
        }
    }
    @media (max-width:799px) {
        .innovator {        
            > button {
                .long-description {
                    max-width: 400px;
                }
                background-color:#E43141;
                .accent {
                    color:#202020;
                }
                svg {
                    stroke:#202020;
                }
            }
        }
    }
    @media (min-width:800px) {
        button {
            display:inline-flex;
        }
        .details {
            display: flex;        
        }
        .long-description {
            height:inherit;
        }
        .innovator {
            .arrow {
                display: flex;
            }
            > button {
                height:130px;
                &.open {
                    height:176px;                    
                }
            }
        }
    }
</style>