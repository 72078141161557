<template>
  <div id="global-vision" class='add-padding'>
      <div class="limit-width">
        <div class='absolute' >
          <PageTitle dark title="Global Vision"/>
          <h4 class="dark">Bringing the World the Most Prestigious and State-of-the-Art Entertainment Experiences.</h4>
        </div>
      </div>
      <div class="scroll-small-window">
        <img src="@/assets/images/oak-view-group/global-vision.jpg" alt="" style="min-width: 900px;">
      </div>
    </div>  
</template>

<script>
import PageTitle from '@/components/PageTitle';
export default {
    name:'GlobalVision',
    components : {
        PageTitle
    }
}
</script>

<style lang="scss" scoped>
#global-vision { 
    background-color: #fff;    
    padding-bottom:45px;
    min-height:calc(100vh - 140px);    
    img {
        height:inherit;
        width: 100%;
        object-fit: contain;
        display: block;
        margin:0 auto;        
        max-width: 1300px;
    }
  }  
</style>