<template>
<div>
  <transition name="slide-fade" mode="out-in" appear>
    <SubNav :items="activePages"/>
  </transition>
  <div class='main'>         
    <Component v-for="page in activePages" :key="page.id" :is="page.component" shallowRef/>
    <Footer />
    </div>
  </div>
</template>

<script>
import { shallowRef } from 'vue'
import axios from 'axios';

import SubNav from '@/components/SubNav';
import Footer from '@/components/Footer';
import AboutOVG from '@/pages/AboutOVG';
import GlobalVision from '@/pages/GlobalVision';
import Leadership from '@/pages/Leadership';
import OVGProperties from '@/pages/OvGProperties';
import LiveNation from '@/pages/LiveNation';

const aboutOVG = shallowRef(AboutOVG)
const globalVision = shallowRef(GlobalVision)
const leadership = shallowRef(Leadership)
const ovgProperties = shallowRef(OVGProperties)
const liveNation = shallowRef(LiveNation)


export default {
  name: 'OakViewGroup',
  components: {
      SubNav,      
      Footer,
  },
  mounted() {
      if(this.$store.state.preview) {
        return;
      }
      const post = {
        method:"page",
        purl:this.$store.state.purl,
        sessionDbId:this.$store.state.sessionDbId,
        section:'1'
      }
      axios.post(this.$store.state.baseUrl + "EpitchLiteTracking.php", post);     
  },
  computed: {
    activePages() {
        let serverPages = this.$store.state.pages.filter(serverPage => {      
            return this.pages.findIndex(page => serverPage.id === page.id) === -1 ? false : true;                
        });
        let pages = [];
        serverPages.forEach(element => {
          pages.push({...element, ...this.pages[this.pages.findIndex(page => element.id === page.id)]});
          
        });
        return pages;
    }
  },
  data: () => ({   
      pages : [
          {
            id:"7",
            path : '/oak-view-group',
            hash : 'about-ovg',
            name : 'About OVG',
            component:aboutOVG
          },
          {
            id:"8",
            path : '/oak-view-group',
            hash : 'global-vision',
            name : 'Global Vision',
            component:globalVision
          },
          {
            id:"9",
            path : '/oak-view-group',
            hash : 'leadership',
            name : 'Leadership',
            component:leadership
          },
          {
            id:"11",
            path : '/oak-view-group',
            hash : 'ovg-properties',
            name : 'OVG Properties',
            component:ovgProperties
          },
          {
            id:"12",
            path : '/oak-view-group',
            hash : 'live-nation',
            name : 'Live Nation',
            component:liveNation
          },
      ] 
  }),
}
</script>